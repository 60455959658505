@import "@/styles/new-variables";
@import '@/styles/functions';

.person{
  .personInfo{
    display: flex;
    align-items: center;
    gap: toRem(28);

    .photoWrapper{
      position: relative;
      width: toRem(124);
      height: toRem(124);
      border-radius: 50%;
      overflow: hidden;
      background: $black;

      .photo{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .personDetails{
      .fullName{
        font-size: toRem(32);
        font-weight: 700;
        line-height: 130%;
        margin-bottom: 1rem;

      }
      .roles{
        display: flex;
        align-items: center;
        gap: toRem(8);
        .role{
          display: flex;
          align-items: center;
          justify-content: center;
          
          font-size: toRem(12);
          font-weight: 500;
          line-height: 130%; 

          border-radius: toRem(6);
          background: $grey-2;
          min-height: toRem(24);
          padding: 0 toRem(6);
          &.active{
            background: $primary;
            color: $black;
          }
        }
      }
    }
  }
}
.authVerificationTabs{
  min-height: 32px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: toRem(4);
  padding: toRem(4);
  background: $transparent-white-10;
  border-radius: toRem(10);
  margin-bottom: toRem(30);
}

.authVerificationTab{
  color: white;
  text-align: center;
  font-size: toRem(13);
  font-weight: 600;
  line-height: 16px;
  transition: all 0.3s ease-in-out 0.02s;
  border-radius: toRem(6);

  &.active{
    background:  white;
    color: $black;
    transition: all 0.7s;
  }
}