@import '@/styles/new-variables';
@import '@/styles/variables';
@import '@/styles/functions';

.authVerificationWrapper {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: toRem(560);
  overflow-y: auto;
  border-radius: 0.5rem;
  
  @media (max-width: get-breakpoint-width('lg')) {
    height: toRem(500);
  }

  .authForm {
    margin: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    height: 100%;
    padding-bottom: toRem(38);

    .description {
      color: $grey-4;
      font-size: toRem(14);
      font-weight: 500;
      line-height: 130%;
      margin-bottom: toRem(10);

      @media (min-width: 470px) {
        font-size: 1.1rem;
      }
    }

    .authFormBody {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }

    .alert {
      margin-top: 0;
    }
  }
}

.errorMessage {
  padding: 0.25rem 0 0;
}

.checkbox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 1rem 0;
}

.lineRow {
  width: 100%;
  border: 0.1px dashed $light-dimmed-color-3;
}

.formFooter {
  .submitButton {
    width: 100%;
  }
}

.link {
  display: block;
  color: $solid-button-signature-bg-color !important;

  @media (min-width: 470px) {
    font-size: 1rem !important;
  }
}

.button {
  margin: 1rem 0;
}

.resetCode {
    color: $grey-5;
    text-align: center;
    font-size: toRem(14);
    line-height: 130%;
    height: toRem(28);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: toRem(8);
}

.sendCode{
  border-radius: 6px;
  background: var(--transparrent-white-white-10, rgba(255, 255, 255, 0.10));
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  font-size: 0.75rem;
  line-height: 130%;
  white-space: nowrap;
  margin: toRem(14) auto;
  padding: toRem(10) toRem(8);
}

.timer {
  border-radius: toRem(6);
  background: $grey-2;
  height: toRem(28);
  width: toRem(50);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 130%;

  &.warning {
    background: rgba(255, 77, 77, 0.30);
  }
}

.buttonGroups {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: get-breakpoint-width('sm')) {
  .formFooter {
    .phoneLoginButtonGroup {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .button {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
}

.smsPhoneInfo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: toRem(8);
    margin: 0 auto; 
    height: toRem(28);
    border-radius: toRem(8);
    background: $grey-1;
    overflow: hidden;
    margin-bottom: toRem(14);

    .phone{
      flex-grow: 1;
      color: white;
      text-align: center;
      font-size: toRem(12);
      font-weight: 600;
      line-height: 130%;
      white-space: nowrap;
      padding: 0 toRem(8) 0 toRem(12);
    }

    .editButton{
      color: $grey-4;
      background: $transparent-white-10;
      display: flex;
      align-items: center;
      justify-content: center;
      width: toRem(28);
      height: toRem(28);
      border-radius: 0 toRem(8) toRem(8) 0;
      cursor: pointer;
    }
}

