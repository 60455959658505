@import '@/styles/functions';
@import '@/styles/new-variables';

.authVerificationHeader{
  display: grid;
  justify-items: center;
  gap: toRem(10);
  margin-bottom: toRem(14);
}

.authVerificationIcon{
  width: toRem(64);
  height: toRem(64);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(192deg, #FFFF4D -23.04%, #F46600 193.98%);
}

.authVerificationTitle{
  font-size: toRem(20);
  font-weight: 600;
  line-height: 130%;
}

.authVerificationSubtitle{
  color: $transparent-white-30;
  text-align: center;
  font-size: toRem(14);
  line-height: 130%;
  margin-bottom: toRem(30);
}